import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './components/LoginPage';
import OverviewPage from './components/OverviewPage';
import YesterdayPage from './components/YesterdayPage';
import ImpressumPage from './components/ImpressumPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Navigate replace to="/overview" />} />
        <Route 
          path="/overview" 
          element={
            <ProtectedRoute>
              <OverviewPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/yesterday" 
          element={
            <ProtectedRoute>
              <YesterdayPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/impressum" 
          element={
            <ProtectedRoute>
              <ImpressumPage />
            </ProtectedRoute>
          } 
        />
        <Route path='*' element={<Navigate replace to="/login" />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
