import React, {useState} from 'react';
import { Tile, Modal } from '@carbon/react';
import Graph from './Graph';

const CardWithTile = ({ machine,title, icon, count, dataType, active }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
        <Tile onClick={toggleModal} style={{ cursor: 'pointer' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <h3>{title}</h3>
                {icon}
            </div>
            <div>
                <p><strong>{count}</strong></p>
            </div>
        </Tile>
        

        {active && (
        <Modal
        open={isModalOpen}
        modalHeading={"Verlauf " + title + " " + machine} // Customize this as needed
        onRequestClose={toggleModal}
        passiveModal
        size='lg'
        >

        <Graph machine={machine} dataType={dataType} isModalOpen={isModalOpen}/>
    </Modal>
        )}
    </>
    );
};

export default CardWithTile;
