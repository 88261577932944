import React, { useState, useEffect, useCallback } from 'react';
import { LineChart } from '@carbon/charts-react';
import "@carbon/charts/scss/index.scss";

import axios from 'axios';

function getTitleForDataType(dataType) {
    switch (dataType) {
      case "pieces":
        return "Stücke";
      case "scheiben":
        return "Scheiben";
      case "sum":
        return "Stücke, Scheiben";
      default:
        return "";
    }
  }

const DetailsGraph = ({ machine, dataType, isModalOpen }) => {
    const [data, setData] = useState([]);

    const options ={
        title: "",
               axes: {
                 bottom: {
                   title: 'Date',
                   mapsTo: 'date',
                   scaleType: 'time'
                 },
                 left: {
                   title: getTitleForDataType(dataType),
                   mapsTo: 'value'
                 }
               },
               toolbar: {
                    enabled: false,
                    zoomIn: false,
                    zoomOut: false,
                    download: false,
                    reset: false,

                },
                legend: {
                    enabled: true,
                    items: {
                        formatter: function({key, data}) {
                            console.log("Key:", key);
                          const dataType = key || data.dataType; // Adjust based on your data structure
                          return getTitleForDataType(dataType);
                        }
                      }
                },
               curve: 'curveMonotoneX',
               height: "500px",
               width: "100%"
     };

      const fetchData = useCallback(async () => {
            axios.get(`https://anvis.combined-automation.de/api/machine/${machine}/status/${dataType}`, {
                headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
            })
            .then(response => {
                console.log("API Response:", response);
                if (response.data.success) {
                    if(dataType === "sum"){
                        const formattedData = response.data.data.map(item => ({
                            group: "pieces",
                            date: new Date(item.date).toISOString(), // Convert ISO string to Date object
                            value: item.pieces
                        }));
                        const formattedData2 = response.data.data.map(item => ({
                            group: "slices",
                            date: new Date(item.date).toISOString(), // Convert ISO string to Date object
                            value: item.slices
                        }));
                        const formattedData3 = formattedData.concat(formattedData2);
                        console.log("Formatted Data:", formattedData3);
                        setData(formattedData3);
                    }
                    else{
                    const formattedData = response.data.data.map(item => ({
                        group: dataType,
                        date: new Date(item.date).toISOString(), // Convert ISO string to Date object
                        value: item[dataType]
                    }));

                    setData(formattedData);
                }
            }})
            .catch(error => {
                console.error('Error fetching graph data', error);
            })
        }, [machine, dataType]); // Add dependencies here

        useEffect(() => {
            if (isModalOpen) {
                fetchData();
            }
        }, [isModalOpen, fetchData]);

    return(
        <div>
            <LineChart data={data} options={options} />
        </div>
    );
};

export default DetailsGraph;
