import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tile, FlexGrid, Row, Column } from '@carbon/react';
import CustomSideNav from './SideNav'; // Adjust the path as necessary
import './OverviewPage.css';
import Card from './Card';

const YesterdayPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get('https://anvis.combined-automation.de/api/machines/yesterday', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setData(response.data.data); // Assuming the response has a data property
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  const renderSummaryTiles = () => {
    // Calculate sums for all machines
    const totalPieces = data.reduce((total, entry) => total + entry.pieces, 0);
    const totalSlices = data.reduce((total, entry) => total + entry.slices, 0);
    const totalSum = totalPieces + totalSlices;

    return (
      <>
        <Row className="summary-tile-row">
          <Column lg={5} md={6} sm={4}><Tile><h3>Stücke </h3><strong>{totalPieces}</strong></Tile></Column>
          <Column lg={5} md={6} sm={4}><Tile><h3>Scheiben </h3><strong>{totalSlices}</strong></Tile></Column>
          <Column lg={5} md={6} sm={4}><Tile><h3>Gesamt </h3><strong>{totalSum}</strong></Tile></Column>
        </Row>
      </>
    );
  };

  const renderMachineTiles = () => {
    return data.map((entry, index) => (
      <div key={index}>
        <h2 className="machine-title">{entry.name}</h2>
        <Row>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Stücke" icon={""} count={entry.pieces} dataType={"pieces"} active={false} />
          </Column>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Scheiben" icon={""} count={entry.slices} dataType={"slices"} active={false} />
          </Column>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Gesamt" icon={""} count={entry.pieces + entry.slices} dataType={"sum"} active={false} />
          </Column>
        </Row>
      </div>
    ));
  };

  return (
    <div className="container-with-sidenav">
      <CustomSideNav />
      <div className="content">
        <FlexGrid fullWidth>
          <Row>
            <Column lg={16}>
            <div className="title-toggle-row" style={{marginTop: "3.5rem"}}>
              <h2 className="page-title">Gestern gesamt</h2>
            </div>
            </Column>
          </Row>
          {renderSummaryTiles()}
          {renderMachineTiles()}
        </FlexGrid>
      </div>
    </div>
  );
};

export default YesterdayPage;
