import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tile, FlexGrid, Row, Column, Toggle} from '@carbon/react';
import CustomSideNav from './SideNav'; // Adjust the path as necessary
import './OverviewPage.css';
import Card from './Card';

const OverviewPage = () => {
  const [data, setData] = useState([]);
  const [autoUpdate, setAutoUpdate] = useState(false);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get('https://anvis.combined-automation.de/api/machines', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let interval;
    if (autoUpdate) {
      interval = setInterval(() => {
        fetchData();
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [autoUpdate]);

  const renderSummaryTiles = () => {
    // Calculate sums for all machines
    const totalPieces = data.reduce((total, entry) => total + entry.sum_pieces, 0);
    const totalSlices = data.reduce((total, entry) => total + entry.sum_slices, 0);
    const totalSum = totalPieces + totalSlices;

    // Adjusted to 3 tiles across all screens
    return (
      <>
      <Row className="summary-tile-row">
        <Column lg={5} md={6} sm={4}><Tile><h3>Stücke </h3><strong>{totalPieces}</strong></Tile></Column>
        <Column lg={5} md={6} sm={4}><Tile><h3>Scheiben </h3><strong>{totalSlices}</strong></Tile></Column>
        <Column lg={5} md={6} sm={4}><Tile><h3>Gesamt </h3><strong>{totalSum}</strong></Tile></Column>
      </Row>
      </>
    );
  };

  const renderMachineTiles = () => {
    return data.map((entry, index) => (
      <>

        <h2 className="machine-title">{entry.name}</h2>
        <Row key={entry.name}>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Stücke" icon={""} count={entry.sum_pieces} dataType={"pieces"} active={true}/>
          </Column>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Scheiben" icon={""} count={entry.sum_slices} dataType={"slices"} active={true}/>
          </Column>
          <Column lg={5} md={6} sm={4}>
            <Card machine={entry.name} title="Gesamt" icon={""} count={entry.sum_slices + entry.sum_pieces} dataType={"sum"} active={true}/>
          </Column>
        </Row>

      </>

    ));
  };

  return (
    <div className="container-with-sidenav">

      <CustomSideNav />
      <div className="content">
        <FlexGrid fullWidth>
          <Row>
            <Column lg={16}>
            <div className="title-toggle-row" style={{marginTop: "3.5rem"}}>
              <h2 className="page-title">Überblick gesamt</h2>
              <Toggle
                labelText="Auto-Update"
                id="auto-update-toggle"
                onToggle={(e) => setAutoUpdate(e)}
                size="md"
              />
            </div>
            </Column>
          </Row>
          {renderSummaryTiles()}
          {renderMachineTiles()}

        </FlexGrid>
      </div>
    </div>


  );
};

export default OverviewPage;