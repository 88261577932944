// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './global.scss'; // Global styles

// Assuming your index.html has a div with id='root'
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
