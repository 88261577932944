import React, { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import './CustomSideNav.css';
import caLogo from '../images/caLogo.png';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  Header,
  SkipToContent,
  HeaderName,
  HeaderMenuButton,
  SideNavDivider
} from '@carbon/react';

const CustomSideNav = () => {
  // State to manage whether the SideNav is expanded or not
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const [username, setUsername] = useState('');
  // Toggle the expanded state of the SideNav
  const toggleSideNav = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };
  useEffect (() => {
    const token = sessionStorage.getItem('token');
    const decoded = jwtDecode(token);
    setUsername(decoded.username);
  }, []);
  
  return (
    <>
      <Header aria-label="ANVIS">
        <HeaderMenuButton
          aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
          isActive={isSideNavExpanded}
          onClick={toggleSideNav}
        />
        <SkipToContent />
        <HeaderName prefix={username}>
          [ANVIS]
        </HeaderName>

        <div className="header-logo">
          <img src={caLogo} alt="Logo" style={{maxHeight:"2rem"}} />
        </div>
      </Header>
      <SideNav 
        aria-label="Side navigation"
        expanded={isSideNavExpanded}
        isChildOfHeader={true}
        onOverlayClick={toggleSideNav}
      >
        <SideNavItems>
          <SideNavLink href="/overview">Überblick</SideNavLink>
          <SideNavLink href="/yesterday">Gestern</SideNavLink>
          <SideNavDivider />
          <SideNavLink href="/impressum">Impressum</SideNavLink>
        </SideNavItems>
      </SideNav>
    </>
  );
};

export default CustomSideNav;