import React, { useState } from 'react';
import { TextInput, Button, InlineNotification } from '@carbon/react';
import axios from 'axios';
import './LoginPage.css'; // Import CSS for additional styling

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://anvis.combined-automation.de/api/login', { username, password });
      sessionStorage.setItem('token', response.data.token);
      window.location.replace('/overview');
    } catch (error) {
      console.error('Login failed:', error.response.data);
      if (error.response.data === 'Invalid credentials') {
        setNotificationMessage('Benutzername oder Passwort falsch');
        setShowNotification(true);
      }
    }
  };

  return (
    <div className="login-page">
      <form className="login-form" onSubmit={handleLogin}>
      {showNotification && (
          <InlineNotification
            kind="error"
            title="Fehler"
            subtitle={notificationMessage}
            onClose={() => setShowNotification(false)}
          />
        )}
        <TextInput 
          id="username" 
          labelText="Benutzername" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <TextInput 
          id="password" 
          labelText="Passwort" 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <Button onClick={handleLogin}>Login</Button>
      </form>
    </div>
  );
};

export default LoginPage;
